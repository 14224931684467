import { useMemo } from 'react';

import { useIsTablet } from '@repo/common/hooks/use-value-by-media';
import { DiscoverViewType } from '@repo/common/services/features-book';
import { useDiscoverViewFlagGetter } from '@repo/common/services/features-book';
import { UiButton } from '@repo/ui-kit/ui-button';

import { CharacterPreviewCarousel } from '@/features/character-preview-carousel';

import { useBrandFeatures } from '@/entities/brand';
import { Character } from '@/entities/character';

import { Nullable } from '@/shared/types';
import { Button, Icon, Typography } from '@/shared/ui';

export interface Props {
  character: Nullable<Character>;

  onClickEdit?: () => void;

  onStartChatting?: () => void;
}

export const Preview = (props: Props) => {
  const { character, onClickEdit, onStartChatting } = props;

  const discoverViewType = useDiscoverViewFlagGetter();
  const isTablet = useIsTablet();

  const features = useBrandFeatures();

  const changeFaceBtn = useMemo(() => {
    if (!features?.change_face_btn) return null;
    if (isTablet && discoverViewType() === DiscoverViewType.ChatWithProfile) return null;
    return (
      <Button onClick={onClickEdit} w="auto" rounded="full" size="icon-sm" variant="outline">
        <Icon k="pen" className="text-[24px]" />
      </Button>
    );
  }, [features?.change_face_btn, isTablet]);

  const startChattingBtn = useMemo(() => {
    if (!isTablet || discoverViewType() !== DiscoverViewType.ChatWithProfile) return null;

    return (
      <UiButton variant="primary" onClick={onStartChatting}>
        Start Chatting
      </UiButton>
    );
  }, [isTablet]);

  return (
    <div className="h-full flex flex-col gap-4 md:py-2 md:px-3">
      <CharacterPreviewCarousel
        character={character ?? null}
        className="relative -mt-[68px] tablet:mt-0 z-[-1] md:z-1
        rounded-tr-none rounded-tl-none tablet:rounded-xl
        h-[600px] md:h-[380px] lg:h-[520px] ring-0 grow"
      />
      <div className="flex flex-col gap-2 px-3 tablet:px-0">
        {startChattingBtn}
        <div className="flex items-center justify-between">
          <Typography as="h5" weight="semibold" className="text-primary-font">
            Bio
          </Typography>

          {changeFaceBtn}
        </div>
        <Typography as="p" className="text-secondary">
          {character?.bio}
        </Typography>
      </div>
    </div>
  );
};
