import { useMemo, useState } from 'react';

import { DIALOG_KEYS, SubscriptionTier } from '@/core/constants';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';

import { EmailForm } from '@/features/email-form';
import { ConstantPaymentsModal, PaymentGatewayModal, SubscriptionCheckoutModal } from '@/features/modals';
import { ConstantPaymentSchema } from '@/features/modals/form/constant-payments-form/constant-payments-form.controller';
import { PaymentGatewaySchema } from '@/features/modals/form/payment-gateway-form/payment-gateway-form.controller';

import { useBrandFeatures } from '@/entities/brand';
import {
  SUBSCRIPTION_PLAN,
  internalSubscriptionListAdapter,
  useCreateConstantposSubscriptionPayment,
  useCreateEpochPaymentIntent,
  useCreateFintmPayment,
  useCreatePaymentGateway,
  useGetConstantposSubscriptions,
  useGetEpochSubscriptions,
  useGetFintmSubscriptions,
  useGetPaymentGatewaySubscriptions,
  useGetSubscriptionList,
} from '@/entities/subscription';

import { CURRENCY_CODE, PAYMENT_SYSTEM } from '@/shared/constants';
import { submitForm } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Alert, dialog } from '@/shared/ui';

export interface Props {
  subscriptionVariant?: SubscriptionTier;

  analyticData?: any;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;

  onSuccessOnboarding?: () => void;
}

export const useSubscription = (props: Props) => {
  const {
    subscriptionVariant = SubscriptionTier.PRO,
    analyticData,
    onSuccessPayment,
    onFailedPayment,
    onSuccessOnboarding,
  } = props;

  const features = useBrandFeatures();

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const viewerQuery = useGetMeQuery();

  const paymentSystem = features?.payment_system as PAYMENT_SYSTEM;

  const lookup_keys = subscriptionVariant === SubscriptionTier.PRO ? SUBSCRIPTION_PLAN.PRO : SUBSCRIPTION_PLAN.PRO_PLUS;

  const { placement } = analyticData || {};

  const queryMap = {
    [PAYMENT_SYSTEM.STRIPE]: useGetSubscriptionList(subscriptionVariant, {
      enabled: paymentSystem === PAYMENT_SYSTEM.STRIPE,
    }),
    [PAYMENT_SYSTEM.FIN_TM]: useGetFintmSubscriptions(
      { lookup_keys: [lookup_keys] },
      { enabled: paymentSystem === PAYMENT_SYSTEM.FIN_TM },
    ),
    [PAYMENT_SYSTEM.CONSTANT_POS]: useGetConstantposSubscriptions(
      { lookup_keys: [lookup_keys] },
      { enabled: paymentSystem === PAYMENT_SYSTEM.CONSTANT_POS },
    ),
    [PAYMENT_SYSTEM.PAYMENT_GATEWAY]: useGetPaymentGatewaySubscriptions(
      { lookup_keys: [lookup_keys] },
      { enabled: paymentSystem === PAYMENT_SYSTEM.PAYMENT_GATEWAY },
    ),
    [PAYMENT_SYSTEM.EPOCH]: useGetEpochSubscriptions(
      { lookup_keys: [lookup_keys] },
      { enabled: paymentSystem === PAYMENT_SYSTEM.EPOCH },
    ),
  };

  const activeSubscriptionQuery = queryMap[paymentSystem];

  const paymentMutationMap = {
    [PAYMENT_SYSTEM.STRIPE]: null,
    [PAYMENT_SYSTEM.FIN_TM]: useCreateFintmPayment(),
    [PAYMENT_SYSTEM.CONSTANT_POS]: useCreateConstantposSubscriptionPayment(),
    [PAYMENT_SYSTEM.PAYMENT_GATEWAY]: useCreatePaymentGateway(),
    [PAYMENT_SYSTEM.EPOCH]: useCreateEpochPaymentIntent(),
  };

  const createPayment = paymentMutationMap[paymentSystem];

  const isFetching =
    activeSubscriptionQuery.isFetching ||
    paymentMutationMap[PAYMENT_SYSTEM.FIN_TM].isPending ||
    paymentMutationMap[PAYMENT_SYSTEM.PAYMENT_GATEWAY].isPending ||
    paymentMutationMap[PAYMENT_SYSTEM.EPOCH].isPending;

  const subscriptionList = useMemo(() => {
    return internalSubscriptionListAdapter(activeSubscriptionQuery?.data ?? []);
  }, [activeSubscriptionQuery?.data]);

  const [selectedSubscription, setSelectedSubscription] = useState(() => subscriptionList.find((sub) => sub.isBest));

  const analyticSuccessfulPurchase = () => {
    if (selectedSubscription) {
      Analytic.purchaseSuccessful({
        item_id: selectedSubscription.id,
        currency: currencyCode,
        price: selectedSubscription?.price / 100,
        type: 'subscription',
        placement: placement ?? 'main_screen',
        payment_provider: 'card',
      });
    }
  };

  const handleSuccessPayment = () => {
    analyticSuccessfulPurchase();

    dialog.close({ key: 'SUBSCRIPTION_CHECKOUT' });
    dialog.open({
      key: 'PAYMENT_SUCCESS',
      component: (
        <Alert type="success" title="Your payment was successful">
          <EmailForm
            onSubmitted={() => {
              Analytic.checkoutEmailSentSuccess();
              dialog.close({ key: 'PAYMENT_SUCCESS' });
              dialog.close({ key: 'BUY_SUBSCRIPTION' });
            }}
            title="Enter your email to get access"
          />
        </Alert>
      ),
      beforeOpen: Analytic.checkoutSuccess,
      afterClose: () => {
        dialog.close({ key: 'BUY_SUBSCRIPTION' });
        onSuccessPayment?.();
        onSuccessOnboarding?.();
      },
    });
  };

  const handleFailedPayment = (error?: string | undefined) => {
    if (!selectedSubscription) return;

    Analytic.purchaseFailed({
      item_id: selectedSubscription.id,
      currency: currencyCode,
      price: selectedSubscription.price / 100,
      type: 'subscription',
      placement: placement ?? 'main_screen',
      payment_provider: 'card',
      error_description: error || '',
    });

    dialog.close({ key: 'SUBSCRIPTION_CHECKOUT' });
    dialog.open({
      key: 'PAYMENT_FAILURE',
      component: <Alert type="error" title="Payment failed" description={error} />,
      afterClose: () => {
        onFailedPayment?.(error);
      },
    });
  };

  const processPayment = async (paymentFunction: any, paymentData: any) => {
    try {
      const response = await paymentFunction.mutateAsync(paymentData);
      if (response.url) window.open(response.url, '_self');
      setTimeout(() => onSuccessOnboarding?.(), 500);
      return response;
    } catch (error) {
      handleFailedPayment(String(error));
    }
  };

  const handleConstantPayment = async (data: ConstantPaymentSchema) => {
    if (!selectedSubscription) return;
    const response = (await processPayment(createPayment, {
      pack_id: selectedSubscription.id,
      email: data.email,
      account: data.account,
      type: 'upi',
      first_name: data.firstName,
      last_name: data.lastName,
    })) as Record<string, any>;
    submitForm(response.transaction.form);
  };

  const handleCreatePaymentGateway = async (data: PaymentGatewaySchema) => {
    if (!selectedSubscription) return;
    const response = (await processPayment(createPayment, {
      product_id: selectedSubscription.id,
      email: data.email,
    })) as Record<string, any>;
    if (response.order_url) {
      dialog.close({ key: DIALOG_KEYS.PAYMENT_GATEWAY_CHECKOUT });
      dialog.close({ key: DIALOG_KEYS.BUY_SUBSCRIPTION });
      window.open(response.order_url, '_self', '');
    } else {
      handleFailedPayment('Order url is null');
    }
  };

  const handleCreateEpochPaymentIntent = async () => {
    const response = (await processPayment(createPayment, {
      pack: selectedSubscription?.id,
    })) as Record<string, any>;
    if (response.redirectUrl) {
      dialog.close({ key: DIALOG_KEYS.BUY_SUBSCRIPTION });
      window.open(response.redirectUrl, '_self', '');
    } else {
      handleFailedPayment('Redirect url is null');
    }
  };

  const handleClickContinue = async () => {
    if (!selectedSubscription) return;

    Analytic.addToCart({
      item_id: selectedSubscription.id,
      currency: currencyCode,
      price: selectedSubscription.price / 100,
      type: 'subscription',
      placement: placement ?? 'main_screen',
    });
    Analytic.beginCheckout({ placement: placement ?? 'main_screen' });

    if (paymentSystem === PAYMENT_SYSTEM.FIN_TM) {
      await processPayment(createPayment, { product_id: selectedSubscription?.id, email: viewerQuery.data?.email });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.CONSTANT_POS) {
      dialog.open({
        key: 'CONSTANT_PAYMENTS_CHECKOUT',
        component: <ConstantPaymentsModal onSubmitPayment={handleConstantPayment} />,
      });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.PAYMENT_GATEWAY) {
      dialog.open({
        key: 'PAYMENT_GATEWAY_CHECKOUT',
        component: <PaymentGatewayModal onSubmitPayment={handleCreatePaymentGateway} />,
      });
      return;
    }

    if (paymentSystem === PAYMENT_SYSTEM.EPOCH) {
      await handleCreateEpochPaymentIntent();
      return;
    }

    dialog.open({
      key: 'SUBSCRIPTION_CHECKOUT',
      component: (
        <SubscriptionCheckoutModal
          onFailedPayment={handleFailedPayment}
          onSuccessPayment={handleSuccessPayment}
          priceId={selectedSubscription.id}
        />
      ),
    });
  };

  return {
    subscriptionList,
    selectedSubscription,
    setSelectedSubscription,
    handleClickContinue,
    currencyCode,
    isFetching,
  };
};
