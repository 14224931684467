import { type AdaptedTokenResponse, authRepository } from '../repository';
import { queryClient } from '@/core/config/react-query';
import { QUERY_KEYS } from '@/core/constants';
import type { UserEntity } from '@repo/api/user';
import { invalidateCharacterListQuery } from '@repo/modules/entity/chat-queries';
import { meQueryKey } from '@repo/modules/entity/user-queries';
import { invalidateGetMeQuery } from '@repo/modules/entity/user-queries';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { authStorageGetters, authStorageSetters } from '@/entities/auth';
import { invalidateGetConversations } from '@/entities/conversation';

import { randomUuid } from '@/shared/libs/utils';

export const useTokenQuery = (clientToken: string, options?: Partial<UseQueryOptions<AdaptedTokenResponse>>) => {
  const version = window.location.host.includes('localhost') ? 'localhost' : window.location.host;

  const defaultCommonDto = {
    platform: 'web',
    version,
    web_to_app: false,
    client_token: clientToken,
  };

  const [sub, token] = [authStorageGetters.getSub(), authStorageGetters.getToken()];

  return useQuery<AdaptedTokenResponse>({
    ...options,
    queryKey: [QUERY_KEYS.Token],
    queryFn: async ({ signal }) => {
      try {
        let innerSub: string;

        if (!sub) {
          innerSub = randomUuid(34);
          authStorageSetters.setSub(innerSub);
        } else {
          innerSub = sub;
        }

        const { data } = await authRepository.getToken({ ...defaultCommonDto, sub: innerSub }, signal);

        authStorageSetters.setToken(data.token);
        return { token: data.token, isNewUser: data.is_new_user, sub: innerSub };
      } catch (error) {
        return Promise.reject(error);
      }
    },
    enabled: (!token || !sub) && options?.enabled,
    ...(token && sub && { initialData: { token, isNewUser: false, sub } }),
  });
};

export const useTokenMutation = (options?: UseMutationOptions<unknown, unknown, string>) => {
  return useMutation({
    ...options,
    mutationFn: async (clientToken) => {
      const version = window.location.host.includes('localhost') ? 'localhost' : window.location.host;

      const defaultCommonDto = {
        platform: 'web',
        version,
        web_to_app: false,
        client_token: clientToken,
        sub: randomUuid(34),
      };

      const { data } = await authRepository.getToken(defaultCommonDto);
      authStorageSetters.setAuth({
        sub: defaultCommonDto.sub,
        token: data.token,
      });
      return data;
    },
  });
};

export const useSignUpMutation = () => {
  return useMutation({
    mutationFn: authRepository.signUp,
    onSuccess: async (data) => {
      authStorageSetters.setAuth({ isAuthenticated: true });
      authStorageSetters.setToken(data.token);
      await invalidateGetMeQuery(queryClient);
      await invalidateCharacterListQuery(queryClient);
      await invalidateGetConversations();
    },
  });
};

export const useSignInMutation = () => {
  return useMutation({
    mutationFn: authRepository.signIn,
    onSuccess: async (data) => {
      authStorageSetters.setAuth({ isAuthenticated: true });
      authStorageSetters.setToken(data.token);
      await invalidateGetMeQuery(queryClient);
      await invalidateCharacterListQuery(queryClient);
      await invalidateGetConversations();
      const me = queryClient.getQueryData<UserEntity>(meQueryKey());
      if (!me) return;
      authStorageSetters.setSub(me.sub);
    },
  });
};

export const useConfirmEmailMutation = () => {
  return useMutation({
    mutationFn: authRepository.confirmEmail,
  });
};

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationFn: authRepository.resetPassword,
  });
};
