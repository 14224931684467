import type { CSSProperties, HTMLAttributes } from 'react';

import { NavItem as NavItemType } from '../../libs';
import { NavItem } from '../nav-item';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLElement> {
  navList: NavItemType[];
}

export const TabBar = (props: Props) => {
  const { navList, className, ...restProps } = props;

  const styles: CSSProperties = {
    gridTemplateColumns: `repeat(${navList.length}, 1fr)`,
  };

  return (
    <nav
      className={cn(
        'z-1 bg-body grow border-t border-t-grey-600 py-2.5 px-2 basis-[68px] h-[68px] shrink-0 flex justify-center items-center ',
        className,
      )}
      {...restProps}
    >
      <ul className="grid justify-items-center gap-0.5 grow" style={styles}>
        {navList.map((item) => {
          return <NavItem isHorizontal item={item} key={item.to} />;
        })}
      </ul>
    </nav>
  );
};
