import { type HTMLAttributes, memo, useMemo } from 'react';

import type { CharacterAdapted } from '../../libs';

import { useBrandFeatures } from '@/entities/brand';

import { cn } from '@/shared/libs/utils';
import { For, Icon, PremiumBadge, Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  character: CharacterAdapted;
  withSubscription?: boolean;
}

function formatNumberToString(num: number): string {
  if (num >= 1000) {
    const kValue = (num / 1000).toFixed(1);
    return `${kValue}K`;
  } else {
    return num.toString();
  }
}

function hashStringToNumber(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
}

function generateViews(character: CharacterAdapted) {
  const hashInput = `${character.id}-${character.name}-${character.age}`;
  const hash = hashStringToNumber(hashInput);
  return formatNumberToString((Math.abs(hash) % 29001) + 5000);
}

export const CharacterOverviewCard = memo((props: Props) => {
  const { character, className, ...restProps } = props;

  const features = useBrandFeatures();

  const views = useMemo(() => {
    return generateViews(character);
  }, [character]);

  const premiumBadge = useMemo(
    () => ({
      icon: typeof features?.premium_badge_content !== 'string' ? features?.premium_badge_content?.icon : undefined,
      label: typeof features?.premium_badge_content === 'string' ? features?.premium_badge_content : undefined,
    }),
    [features],
  );

  return (
    <div
      className={cn(
        'relative overflow-hidden rounded-xl aspect-[1/1.6] lg:aspect-[1/1.2]',
        'before:absolute z-1 before:bottom-0 before:left-0 before:w-full before:h-1/2 before:bg-gradient-to-t before:from-black/90',
        className,
      )}
      {...restProps}
    >
      <img className="absolute h-full object-cover -z-1" alt={character.name} src={character.picture_url} />
      <div className="relative flex flex-col justify-between px-3 pt-2 pb-4 h-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <Icon className="text-white text-3xl" k="eye-view" />
            <Typography as="span" weight="medium" variant="2sm" className="text-white">
              {views}
            </Typography>
          </div>
          {character.is_premium && <PremiumBadge label={premiumBadge.label} iconKey={premiumBadge.icon} />}
        </div>
        <div>
          <Typography as="span" variant="xl" weight="semibold" className="text-white md:text-3xl">
            {character.name}
          </Typography>
          <div className="flex gap-1.5 items-center flex-wrap pt-2">
            <For
              each={character.interests}
              render={(interest) => {
                return (
                  <span
                    key={interest}
                    className="text-xs font-medium lg:text-sm text-white rounded-full bg-white/30 backdrop-blur px-1.5 py-px"
                  >
                    {interest}
                  </span>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
