import { HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { useMatchRoute } from '@tanstack/react-router';
import { useBreakpoint } from 'use-breakpoint';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import { useBrandFeatures } from '@/entities/brand';
import { Navbar, RootLayoutContextProvider, RootLayoutContextValue } from '@/entities/layout';
import { TabBar } from '@/entities/layout/ui/tab-bar';
import { useCustomiseAi } from '@/entities/customise-ai';

import { cn } from '@/shared/libs/utils';
import type { PathTo } from '@/shared/types';

import { NAVIGATION_LINKS } from './config';


export interface Props extends HTMLAttributes<HTMLDivElement> {
}

const tapBarExcludedPaths: PathTo[] = ['/conversations/$chatId', '/chats/$characterId', '/404', '/docs/$doc'];

export const RootLayout = (props: Props) => {
  const { className, children, ...restProps } = props;

  const { 0: isOpenSidebar, 1: setIsOpenSidebar } = useState(false);

  const { breakpoint } = useBreakpoint({ mobile: 0, tablet: 768, desktop: 1280 });

  const isMobile = useMemo(() => breakpoint === 'mobile', [breakpoint]);

  const matchRoute = useMatchRoute();

  const isHiddenTabBar = tapBarExcludedPaths.some((path) => matchRoute({ to: path }));

  const customiseAi = useCustomiseAi();

  const features = useBrandFeatures();

  const onToggleSidebar = useCallback(() => {
    setIsOpenSidebar((open) => !open);
  }, []);

  const onCloseSidebar = useCallback(() => {
    setIsOpenSidebar(false);
  }, []);

  const onOpenSidebar = useCallback(() => {
    setIsOpenSidebar(true);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isOpenSidebar && breakpoint === 'mobile') {
      setIsOpenSidebar(false);
    }
  }, [breakpoint]);

  const value: RootLayoutContextValue = useMemo(
    () => ({
      isMobile,
      isOpenSidebar,
      onToggleSidebar,
      onCloseSidebar,
      onOpenSidebar,
    }),
    [isMobile, isOpenSidebar],
  );

  return (
    <RootLayoutContextProvider value={value}>
      <div className={cn('flex  h-full transition-all', className)} {...restProps}>
        <Navbar
          navList={features ? NAVIGATION_LINKS(features?.create_ai, customiseAi?.enabled) : []}
          className={cn(
            'hidden transition-all w-full md:block grow shrink-0 basis-[--sidebar-collapsed-width] max-w-[--sidebar-collapsed-width]',
            {
              'md:basis-[--sidebar-width] md:max-w-[--sidebar-width]': isOpenSidebar,
            },
          )}
        />
        <div className={cn('flex flex-col h-full transition-all grow')}>
          <div
            className={cn('grow h-full pb-[68px] md:pb-0', {
              'pb-0': isHiddenTabBar,
            })}
          >
            {children}
          </div>
          {!isHiddenTabBar && (
            <TabBar
              className="fixed bottom-0 inset-x-0 md:hidden"
              navList={features ? NAVIGATION_LINKS(features?.create_ai, customiseAi?.enabled) : []}
            />
          )}
        </div>
      </div>
    </RootLayoutContextProvider>
  );
};
