import { memo } from 'react';
import { SpinnerProps } from '../type';
import './index.css';

export const HeartBeat3DKey = 'heart-beat-3d';

export interface Props extends SpinnerProps {}

export const HeartBeat3D = memo((props: Props) => {
  const { ...restProps } = props;

  return <span data-variant={HeartBeat3DKey} {...restProps} />;
});
