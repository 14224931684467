import { GoogleLogin } from '@react-oauth/google';
import { GoogleAuthProvider } from 'firebase/auth';

import { GoogleSignUpFormControllerParams, useGoogleSignUpFormController } from './google-sign-up-form.controller';

export interface Props extends GoogleSignUpFormControllerParams {
}

export const GoogleSignUp = (props: Props) => {
  const { onSuccess, onError, analyticData } = props;

  const { onSignUp } = useGoogleSignUpFormController({
    onSuccess,
    onError,
    analyticData,
  });

  return (
    <GoogleLogin
      text="signup_with"
      onSuccess={resp => {
        const idToken = resp.credential;
        const credential = GoogleAuthProvider.credential(idToken);
        onSignUp(credential);
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  );
};
