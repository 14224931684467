import type { HTMLAttributes } from 'react';

import { UiSpinnerProps } from '../ui-spinner';
import { UiSpinner } from '../ui-spinner';
import { cn } from '@repo/common/utils/component';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const splashScreen = cva(
  'ui-splash-screen ui-inset-0 ui-size-full ui-flex ui-flex-col ui-justify-center ui-items-center ui-bg-black ui-z-splash-screen',
  {
    variants: {
      position: {
        fixed: 'ui-fixed',
        absolute: 'ui-absolute',
      },
      opacity: {
        '0': 'ui-bg-black/0',
        '30': 'ui-bg-black/30',
        '60': 'ui-bg-black/60',
        '80': 'ui-bg-black/80',
        '100': 'ui-bg-black',
      },
    },
  },
);

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof splashScreen> {
  spinnerKey?: UiSpinnerProps['k'];
  spinnerProps?: Omit<UiSpinnerProps, 'k'>;
}

export const UiSplashScreen = (props: Props) => {
  const {
    className,
    spinnerKey = 'line-wobble',
    position = 'fixed',
    opacity = '100',
    spinnerProps,
    style,
    ...restProps
  } = props;

  const { className: spinnerClassName, ...restSpinnerProps } = spinnerProps ?? {};

  return (
    <div className={cn(splashScreen({ position, opacity }), className)} {...restProps}>
      <UiSpinner k={spinnerKey} className={cn('ui-relative', spinnerClassName)} style={style} {...restSpinnerProps} />
    </div>
  );
};
