import { useMemo } from 'react';

import { queryClient } from '@/core/config/react-query';
import { LocalStorageKey, localStorageService } from '@/core/services';
import { routeTree } from '@/routeTree.gen';
import { useFeatures } from '@repo/common/services/features-book';
import { RouterProvider, createRouter } from '@tanstack/react-router';

import { AppScripts } from '@/app/app-scripts';

import { useListenInternetConnection } from '@/features/listen-internet-connection';
import { useWatchAuthStorageUpdating } from '@/features/watch-auth-storage';

import { type BrandPayloadModel, useSelectBrandConfig } from '@/entities/brand';
import '@/entities/brand';

import { SplashScreen } from '@/shared/ui';

export const router = createRouter({
  routeTree: routeTree,
  defaultPendingComponent: () => <SplashScreen spinnerKey="line-wobble" />,
  context: {
    queryClient: queryClient,
    isPassOnboarding: () => localStorageService.getItem(LocalStorageKey.IsPassOnboarding, false),
    brandConfig: {} as BrandPayloadModel & {
      version: number;
    },
    featuresGetter: {} as any,
  },
  defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const AppRouter = () => {
  const brandConfig = useSelectBrandConfig();
  const features = useFeatures();

  const context = {
    brandConfig: useMemo(() => ({ ...(brandConfig ?? {}) }), [brandConfig]),
    featuresGetter: <T,>(key: string, defaultValue?: T): T => {
      return features[key]?.value || defaultValue;
    },
  };

  useListenInternetConnection();
  useWatchAuthStorageUpdating();

  return (
    <AppScripts constants={context?.brandConfig?.constants as any}>
      <RouterProvider router={router} context={context as any}></RouterProvider>
    </AppScripts>
  );
};
