import { HTMLAttributes, ReactNode } from 'react';
import { ValueByMedia } from '@repo/common/hooks/use-value-by-media';
import { ClassNames, Nullable } from '@repo/common/types/helpers';
import { CloseButtonProps } from './_ui/close-button';

export enum DialogType {
  Alert = 'alert',
  Modal = 'modal',
}

export type DialogPlacement = 'top' | 'bottom' | 'left' | 'right' | 'center';

export type CloseBtnPlacement = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export type DialogProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & {
  id: string;
  closeOnEsc?: boolean;
  withCloseButton?: boolean;
  onClose?: (id: string) => void;
  zIndex?: Nullable<number>;
  onMount?: () => void;
  onUnmount?: () => void;
  placement?: ValueByMedia<DialogPlacement>;
  classNames?: ClassNames<'root' | 'overlay'>;
  fullScreen?: ValueByMedia<boolean>;
  showCloseBtn?: boolean;
  closeBtnProps?: CloseButtonProps & {
    placement?: CloseBtnPlacement;
  };
};

export type ModalProps = DialogProps & {};

export type AlertProps = DialogProps & {
  closeDelay?: Nullable<number>;
};

export type OpenDialogPayload<T extends DialogType = DialogType.Modal> = {
  content: ReactNode;
  id?: string;
  hidePrevDialogs?: boolean;
  type?: T;
  props?: T extends DialogType.Modal ? Omit<ModalProps, 'id'> : Omit<AlertProps, 'id'>;
};

export type DialogItem = {
  id: string;
  content: ReactNode;
  isHidden: boolean;
  onClose: () => void;
} & (
  | { type: DialogType.Alert; props?: AlertProps }
  | {
      type: DialogType.Modal;
      props?: ModalProps;
    }
);
