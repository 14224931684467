import { type ComponentType, type ReactNode, useCallback, useMemo, useState } from 'react';

import { createSafeContext } from '@/shared/libs/context';

interface Props {
  children: ReactNode;
}

interface Value {
  pagesCount: number;

  pageNumber: number;

  onChangePage: (pageNumber: number) => void;

  onLoadSuccess: (data: { numPages: number }) => void;

  onNextPage: () => void;

  onPrevPage: () => void;
}

const [Provider, usePDFViewer] = createSafeContext<Value>('PDFViewerProvider');

const PDFViewerProvider = (props: Props) => {
  const { children } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);

  const onChangePage = useCallback((pageNumber: number) => {
    setPageNumber(pageNumber);
  }, []);

  const onLoadSuccess = useCallback((data: { numPages: number }) => {
    setPagesCount(data.numPages);
  }, []);

  const onNextPage = useCallback(() => {
    setPageNumber((prevState) => {
      if (pagesCount > prevState) {
        return prevState + 1;
      }
      return prevState;
    });
  }, [pagesCount]);

  const onPrevPage = useCallback(() => {
    setPageNumber((prevState) => {
      if (prevState > 1) {
        return prevState - 1;
      }
      return prevState;
    });
  }, []);

  const value: Value = useMemo(
    () => ({
      pagesCount,
      pageNumber,
      onChangePage,
      onLoadSuccess,
      onNextPage,
      onPrevPage,
    }),
    [pageNumber, pagesCount],
  );

  return <Provider value={value}>{children}</Provider>;
};

const withPDFViewerContext = <P extends object>(Component: ComponentType<P>) => {
  return (props: P) => {
    return (
      <PDFViewerProvider>
        <Component {...props} />
      </PDFViewerProvider>
    );
  };
};

export { withPDFViewerContext, usePDFViewer };
