import { createEventEmitter } from '@repo/common/services/event-emitter';
import { createStorage } from '@repo/common/services/storage';

export interface IChatRatingStackItem {
  isRated: boolean;
  messageReactionStack: Record<string, number>;
  messageSentQuantity: number;
}

export interface IChatRatingStack {
  [key: string]: IChatRatingStackItem;
}

export interface IChatStorageValue {
  visitedChats: boolean;
  ratingStack: IChatRatingStack;
  generalSentMessagesQuantity: number;
}

enum ChatStorageKey {
  Chat = 'chat',
}

export interface ChatStorageListeners {
  onChatChange: Partial<IChatStorageValue>;
}

export interface IChatStorageValueStorage {
  [ChatStorageKey.Chat]: IChatStorageValue;
}

const eventEmitter = createEventEmitter<ChatStorageListeners>();
const storage = createStorage<IChatStorageValueStorage>(window.localStorage);

const defaultChat: IChatStorageValue = {
  visitedChats: false,
  ratingStack: {},
  generalSentMessagesQuantity: 0,
};

const getters = {
  getStorage() {
    return storage.getItem(ChatStorageKey.Chat, defaultChat);
  },
  getVisitedChats() {
    return this.getStorage().visitedChats;
  },
  getGeneralSentMessagesQuantity() {
    return this.getStorage().generalSentMessagesQuantity;
  },
  getRatingStack() {
    return this.getStorage().ratingStack;
  },
  getChatRatingStackValue(chatId: string) {
    const value = this.getRatingStack()?.[chatId];
    if (!value) return null;
    return value;
  },
  getChatIsRated(chatId: string) {
    return this.getChatRatingStackValue(chatId)?.isRated ?? false;
  },

  getGeneralChatMessagesQuantity(chatId: string) {
    return Object.keys(this.getChatRatingStackValue(chatId)?.messageReactionStack ?? {}).length ?? 0;
  },
};

const setters = {
  setStorage(chat: Partial<IChatStorageValue>) {
    const val = getters.getStorage();

    storage.setItem(ChatStorageKey.Chat, { ...val, ...chat });

    eventEmitter.emit('onChatChange', chat);
  },
  setVisitedChats(visitedChats: boolean) {
    this.setStorage({ visitedChats });
  },

  setGeneralSentMessagesQuantity(generalSentMessagesQuantity: number) {
    this.setStorage({ generalSentMessagesQuantity });
  },

  incrementGeneralSentMessagesQuantity() {
    this.setGeneralSentMessagesQuantity(getters.getStorage().generalSentMessagesQuantity + 1);
  },

  setRatingStack(ratingStack: IChatRatingStack) {
    if (!ratingStack) return;
    this.setStorage({ ratingStack });
  },
  setChatRatingStackItem(chatId: string, chatRatingStackValue?: Partial<IChatRatingStackItem>) {
    const ratingStack = getters.getRatingStack();
    const currentChatRatingStackValue = getters.getChatRatingStackValue(chatId) ?? {};
    this.setRatingStack({
      ...ratingStack,
      [chatId]: {
        isRated: false,
        messageReactionStack: {},
        messageSentQuantity: 0,
        ...currentChatRatingStackValue,
        ...(chatRatingStackValue ?? {}),
      },
    });
  },
  setChatIsRated(chatId: string, isRated: boolean) {
    this.setChatRatingStackItem(chatId, {
      isRated,
    });
  },
  setChatMessageReaction(chatId: string, messageId: string) {
    const chatRatingStackValue = getters.getChatRatingStackValue(chatId);

    this.setChatRatingStackItem(chatId, {
      messageReactionStack: {
        ...chatRatingStackValue?.messageReactionStack,
        [messageId]: 1,
      },
    });
  },
  setChatMessageSentQuantity(chatId: string, messageSentQuantity: number) {
    this.setChatRatingStackItem(chatId, {
      messageSentQuantity,
    });
  },

  incrementChatMessageSentQuantity(chatId: string) {
    const chatRatingStackValue = getters.getChatRatingStackValue(chatId);

    this.setChatRatingStackItem(chatId, {
      messageSentQuantity: (chatRatingStackValue?.messageSentQuantity ?? 0) + 1,
    });
  },
};

const listeners = {
  onStorageChange(callback: (chat: Partial<IChatStorageValue>) => void) {
    return eventEmitter.on('onChatChange', callback);
  },
};

export const chatStorage = {
  ...getters,
  ...setters,
  ...listeners,
} as const;
