import { randomId } from '@repo/common/utils/base';
import { createSelectors } from '@repo/common/utils/zustand';
import { create } from 'zustand';
import { DialogItem, DialogType, OpenDialogPayload } from '../types';

type DialogState = {
  stack: DialogItem[];
};

const defaultState: DialogState = {
  stack: [],
};

const useBaseDialogState = create<DialogState>(() => defaultState);

const clearDialog = () => {
  useBaseDialogState.setState(defaultState);
};

const closeDialog = (id: string) => {
  useBaseDialogState.setState((state) => {
    const newStack = state.stack
      .filter((item) => item.id !== id)
      .map((item, idx, arr) =>
        idx === arr.length - 1
          ? {
              ...item,
              isHidden: false,
            }
          : item,
      );

    return {
      stack: newStack,
    };
  });
};

const openDialog = <T extends DialogType = DialogType.Modal>({
  content,
  id = randomId(),
  hidePrevDialogs = true,
  type = DialogType.Modal as T,
  props,
}: OpenDialogPayload<T>) => {
  useBaseDialogState.setState((state) => {
    const isExist = state.stack.some((item) => item.id === id);

    if (isExist) {
      return {
        stack: state.stack.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              content,
              isHidden: false,
              props: { ...item?.props, ...props, id },
            };
          }

          return item;
        }),
      };
    }

    return {
      stack: [
        ...state.stack.map((item) => ({
          ...item,
          isHidden: hidePrevDialogs,
        })),
        {
          id,
          content,
          isHidden: false,
          onClose: () => closeDialog(id),
          type,
          props: { ...props, id },
        },
      ],
    };
  });

  return () => closeDialog(id);
};

export const useDialogState = createSelectors(useBaseDialogState);

export const dialog = {
  open: openDialog,
  close: closeDialog,
  clear: clearDialog,
};
