import { NavItem } from '@/entities/layout';

import { Icon } from '@/shared/ui';

export const NAVIGATION_LINKS = (isCreateAi?: boolean, isCustomizeAi?: boolean): NavItem[] => {

  const createAi: NavItem[] = isCreateAi
    ? [
      {
        label: 'Create Ai',
        to: '/create-ai',
        icon: <Icon k="create" />,
      },
    ]
    : [];

  const customizeAi: NavItem[] = isCustomizeAi
    ? [
      {
        label: 'Customize Ai',
        to: '/customise-ai',
        icon: <Icon k="pen" />,
      },
    ]
    : [];

  const result: NavItem[] = [
    {
      label: 'Discover',
      to: '/discover',
      icon: <Icon k="discover" />,
    },
    {
      label: 'Chats',
      to: '/conversations',
      icon: <Icon k="chats" />,
      hasUnreadBadge: true,
    },
    ...createAi,
    ...customizeAi,
    {
      label: 'Settings',
      to: '/settings',
      icon: <Icon k="settings" />,
    },
  ];
  return result;
};
