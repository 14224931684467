import { useRef } from 'react';

import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useUpdateMeMutation } from '@repo/modules/entity/user-queries';

import { useBrandFeatures } from '@/entities/brand';

import { CookiesDictionary } from '@/shared/constants';

export const useRedtrackClickId = () => {
  const updateMe = useUpdateMeMutation();
  const features = useBrandFeatures();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useIsomorphicEffect(() => {
    if (!features?.third_party?.red_track?.src) return;

    const handleClickId = async () => {
      let rtkclickid = extractCookieValue(CookiesDictionary.RED_TRACK);

      if (rtkclickid) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        await updateMe.mutateAsync({
          rtkclickid_store: rtkclickid,
        });
      }
    };

    intervalRef.current = setInterval(() => {
      handleClickId();
    }, 200);

    handleClickId();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);
};
