import { CSSProperties, HTMLAttributes } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  imgSrc?: string;

  withShadowBottom?: boolean;
}

export const FullSizeCharacterContainer = (props: Props) => {
  const { imgSrc, className, style, withShadowBottom, children, ...restProps } = props;

  const styles: CSSProperties = {
    ...(imgSrc && { backgroundImage: `url(${imgSrc})` }),
    ...style,
  };

  return (
    <div
      className={cn(
        'relative bg-center bg-cover rounded-2xl overflow-hidden w-full h-full ring-1 ring-accent-primary',
        {
          'before:absolute before:h-[50%] before:pointer-events-none before:bg-gradient-to-t before:from-black/90 before:via-black/50 before:via-60% before:to-black/0 before:z-[1] before:inset-x-0 before:bottom-0':
            withShadowBottom,
        },
        className,
      )}
      style={styles}
      {...restProps}
    >
      {children}
    </div>
  );
};
