import { ExtractParams } from '../types/utility';

const createRoot = <T extends string>(root: T) => {
  return {
    root: `/${root}`,
    append: <P extends string>(path: P) => {
      return `${root}${path}` as `/${T}${P}`;
    },
  } as const;
};

const replaceParams = <T extends string>(url: T, params: ExtractParams<T>) => {
  return Object.entries(params).reduce((acc: string, [key, value]) => acc.replace(`:${key}`, value as string), url);
};

const pathToFunction = <
  T extends string,
  Params extends ExtractParams<T> = ExtractParams<T>,
  HasParams = keyof Params extends never ? false : true,
>(
  path: T,
) => {
  return ((params?: HasParams extends true ? Params : never) => {
    if (params) {
      return replaceParams(path, params);
    }
    return path;
  }) as HasParams extends true ? (params: Params) => string : () => string;
};

const brandConfig = createRoot('brand-config');
const stripe = createRoot('stripe');
const fintm = createRoot('fintm');
const user = createRoot('user');
const chat = createRoot('chat');
const auth = createRoot('auth');
const system = createRoot('system');
const character = createRoot('character');
const message = createRoot('message');
const constructor = createRoot('constructor');

export const authEndpoints = {
  createToken: pathToFunction(auth.append('/token')),
  signUpWithEmail: pathToFunction(auth.append('/email/connect')),
  signUpWithGoogle: pathToFunction(auth.append('/oauth-sign-in')),
  signInWithEmail: pathToFunction(auth.append('/email/login')),
  confirmEmail: pathToFunction(auth.append('/email/confirm')),
  resetPassword: pathToFunction(auth.append('/email/reset')),
} as const;

export const brandConfigEndpoints = {
  getWebConfig: pathToFunction(brandConfig.append('/web')),
} as const;

export const characterEndpoints = {
  getCharacters: pathToFunction(character.root),
  createCharacter: pathToFunction(character.root),
  updateCharacter: pathToFunction(character.append('/:characterId')),
} as const;

export const userEndpoints = {
  me: pathToFunction(user.append('/me')),
  updateMe: pathToFunction(user.append('/update')),
} as const;

export const messageEndpoints = {
  editMessage: pathToFunction(message.append('/:messageId/edit')),
  sendMessageFeedback: pathToFunction(message.append('/:messageId/feedback')),
} as const;

export const chatEndpoints = {
  chats: pathToFunction(chat.root),
  getMessages: pathToFunction(chat.append('/:chatId/message')),
  sendMessage: pathToFunction(chat.append('/:chatId/message')),
  sendRandomMessage: pathToFunction(chat.append('/:chatId/message/random')),
  updateCharacterInfo: pathToFunction(chat.append('/:chatId/character')),
  resetCharacterInfo: pathToFunction(chat.append('/:chatId/character/reset')),
} as const;

export const systemEndpoints = {
  uploadPhoto: pathToFunction(system.append('/uploadphoto')),
} as const;

export const constructorEndpoints = {
  generateCharacterPhoto: pathToFunction(constructor.append('/photo')),
} as const;

export const stripeEndpoints = {
  getStripeRoot: pathToFunction(stripe.root),
  createSubscription: pathToFunction(stripe.append('/create-subscription')),
  createCustomer: pathToFunction(stripe.append('/create-customer')),
  creditPacks: pathToFunction(stripe.append('/packs')),
  getPrices: pathToFunction(stripe.append('/prices')),
  createPaymentIntent: pathToFunction(stripe.append('/create')),
} as const;

export const fintmEndpoints = {
  creditPacks: pathToFunction(fintm.append('/packs')),
  prices: pathToFunction(fintm.append('/prices')),
  fintmPayment: pathToFunction(fintm.append('/create')),
} as const;
