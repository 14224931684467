import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Scrollbar } from '@radix-ui/react-scroll-area';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<typeof Scrollbar> {}

export const UiScrollAreaScrollbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { orientation = 'vertical', className, ...restProps } = props;
  return (
    <Scrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        'ui-scroll-area-scrollbar ui-p-px',
        {
          'ui-h-full ui-w-2.5 ui-border-l ui-border-l-transparent': orientation === 'vertical',
          'ui-h-2.5 ui-w-full ui-border-t ui-border-t-transparent': orientation === 'horizontal',
        },
        className,
      )}
      {...restProps}
    />
  );
});
