import { Suspense } from 'react';

import { AppInitialize } from '@/app/app-initialize';
import { AppProviders } from '@/app/app-providers';
import { AppRouter, router } from '@/app/app-router';

import { SentryService } from '@/shared/services/sentry';
import { SplashScreen } from '@/shared/ui';

SentryService.init(router);

export function App() {
  return (
    <Suspense fallback={<SplashScreen spinnerKey="line-wobble" />}>
      <AppProviders>
        <AppInitialize>
          <AppRouter />
        </AppInitialize>
      </AppProviders>
    </Suspense>
  );
}
