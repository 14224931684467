import { useMemo } from 'react';
import { useFeatureGetter } from '@repo/common/services/features-book';

import * as RadioGroup from '@radix-ui/react-radio-group';
import {
  bestValueStyles,
  discountBadgeStyles,
  priceLabelItemStyles,
  priceLabelStyles,
  priceRadioBtn,
  priceStyles,
  radioBtnStyles,
} from './price-radio-btn.config';
import './styles.css';
import { VariantProps } from 'class-variance-authority';
import {
  RECURRING_PRICE_RECALCULATION,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTIONS_VIEW,
  useSubscriptionPriceRecalculation,
} from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { currencyConverter } from '@/shared/libs/currency-converters';
import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';

export interface Props extends RadioGroup.RadioGroupItemProps, VariantProps<typeof priceRadioBtn> {
  period: SUBSCRIPTION_PERIOD;

  price: number;

  discount: number;

  currencyCode: CURRENCY_CODE;

  subscriptionView: SUBSCRIPTIONS_VIEW;
}

export const PriceRadioBtn = (props: Props) => {
  const {
    className,
    isActive,
    isBest,
    period,
    price,
    discount = 0,
    currencyCode,
    subscriptionView,
    ...restProps
  } = props;

  const priceLabel = useMemo(() => {
    switch (period) {
      case SUBSCRIPTION_PERIOD.MONTHLY:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'MONTHLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'MONTHLY',
        };
      case SUBSCRIPTION_PERIOD.ANNUAL:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'YEARLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'YEARLY',
        };
      case SUBSCRIPTION_PERIOD.QUARTERLY:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'QUARTERLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'QUARTERLY',
        };
      case SUBSCRIPTION_PERIOD.WEEKLY:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'WEEKLY ACCESS',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'WEEKLY',
        };
      default:
        return {
          [SUBSCRIPTIONS_VIEW.DEFAULT]: 'UNKNOWN',
          [SUBSCRIPTIONS_VIEW.SQUARE]: 'UNKNOWN',
        };
    }
  }, [period, subscriptionView]);

  const recalculationType = useFeatureGetter<string>('subscription-price-recalculation', 'none');

  const recalculatedPrice = useSubscriptionPriceRecalculation(period, recalculationType());

  const recalculationTypeLabel = useMemo(() => {
    const type = recalculationType();
    return (period === SUBSCRIPTION_PERIOD.WEEKLY && type === RECURRING_PRICE_RECALCULATION.MONTH) ? 'week' : type;
  }, [period, recalculationType]);

  const originalPrice = useMemo(() => {
    const decimalDiscount = discount / 100;

    return currencyConverter(currencyCode).format(recalculatedPrice(price) / 100 / (1 - decimalDiscount));
  }, [discount, price, currencyCode]);

  const discountEl = useMemo(() => {
    const formattedDiscount = discount > 0 ? `-${discount}%` : null;

    if (!formattedDiscount) {
      return null;
    }

    return (
      <span className={cn('pl-1')}>
        <Typography as="span" variant="2sm" weight="semibold" className={cn(discountBadgeStyles({ subscriptionView }))}>
          {formattedDiscount}
        </Typography>
      </span>
    );
  }, [discount]);

  return (
    <RadioGroup.Item className={cn(priceRadioBtn({ subscriptionView, isActive, isBest }), className)} {...restProps}>
      <div className="flex items-center justify-between gap-2 h-full">
        <div className={cn(priceLabelStyles({ subscriptionView }))}>
          <Typography
            as="h6"
            variant="base"
            weight="semibold"
            className="flex flex-wrap gap-2 items-center justify-center text-primary-font"
          >
            <span className={cn(priceLabelItemStyles({ subscriptionView }))}>{priceLabel[subscriptionView]}</span>
            {discountEl}
          </Typography>

          <Typography as="div" weight="semibold" className={cn(priceStyles({ subscriptionView }))}>
            {discount > 0 && subscriptionView !== SUBSCRIPTIONS_VIEW.SQUARE && (
              <Typography as="div" variant="2xs" className="line-through pr-1.5">
                {originalPrice}
              </Typography>
            )}
            {currencyConverter(currencyCode).format(recalculatedPrice(price) / 100)}
            {
              recalculationType() !== RECURRING_PRICE_RECALCULATION.NONE &&
              <>
                /<span>{recalculationTypeLabel}</span>
              </>
            }
          </Typography>
        </div>
        <div className={cn(radioBtnStyles({ subscriptionView }))}>
          <RadioGroup.Indicator
            className={cn(
              'relative flex items-center justify-center w-full h-full transition-all',
              'after:inset-0 after:block after:w-[calc(100%-6px)] after:h-[calc(100%-6px)] after:rounded-full after:bg-brand-default',
            )}
          />
        </div>
      </div>
      {isBest && (
        <Typography as="span" weight="semibold" variant="2sm" className={cn(bestValueStyles({ subscriptionView }))}>
          Best value
        </Typography>
      )}
    </RadioGroup.Item>
  );
};
