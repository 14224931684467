import { HTMLAttributes, useEffect, useMemo } from 'react';

import { SubscriptionTier } from '@/core/constants';
import { useFeature } from '@repo/common/services/features-book';

import { useBrandFeatures } from '@/entities/brand';
import { ModalPreviewImg } from '@/entities/modals';
import {
  BenefitType,
  CreditIconPacks,
  SUBSCRIPTION_PERIOD,
  SubscriptionBenefitList,
  useSubscription,
} from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { currencyConverter } from '@/shared/libs/currency-converters';
import { cn } from '@/shared/libs/utils';
import { Button, SpinnerIcon, Typography } from '@/shared/ui';

const benefitsList = [
  {
    key: 'chat',
    icon: 'picture',
    label: 'NSFW With No Blur',
  },
  {
    key: 'photos',
    icon: 'coronet',
    label: 'Unlock All PRO Models',
  },
  {
    key: 'roleplay',
    icon: 'mask',
    label: 'Virtual Sex Mode',
  },
  {
    key: 'memory',
    icon: 'brain',
    label: 'Advanced AI Memory',
  },
];

export interface Props extends HTMLAttributes<HTMLDivElement> {
  analyticData?: any;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;
}

export const WelcomeOffer = (props: Props) => {
  const { analyticData, onSuccessPayment, onFailedPayment, className } = props;

  const features = useBrandFeatures();

  const welcomeOffer = useFeature('welcome-offer', {
    credits: 0,
    discount: 0,
    discount_bg: '',
    photo: '',
    credits_per_currency_unit: 0,
  });

  const benefits = benefitsList.map((benefit) => {
    return {
      key: benefit.key,
      icon: benefit.icon,
      label: benefit.label,
    };
  }) as BenefitType[];

  const subscriptionVariant = SubscriptionTier.PRO;

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const creditImgPack = (CreditIconPacks as any)?.[features?.credit_modal.credit_icon_type as string];

  const { setSelectedSubscription, subscriptionList, selectedSubscription, handleClickContinue, isFetching } =
    useSubscription({
      subscriptionVariant,
      analyticData,
      onSuccessPayment,
      onFailedPayment,
    });

  const isDisabledContinue = !selectedSubscription || isFetching;

  const savedPrice = useMemo(() => {
    if (!selectedSubscription) return;

    return Math.ceil(selectedSubscription?.price / 100 + 150 / welcomeOffer?.credits_per_currency_unit);
  }, [selectedSubscription]);


  const originalPrice = useMemo(() => {
    if (!selectedSubscription) return;

    if (savedPrice) {
      const price = currencyConverter(currencyCode).format(selectedSubscription.price / 100 + savedPrice);
      return (
        <Typography as="p" weight="medium" className="text-lg pl-1.5 line-through pr-1.5">
          {price}
        </Typography>
      );
    }
  }, [selectedSubscription, savedPrice, currencyCode]);

  useEffect(() => {
    const sub = subscriptionList.find((sub) => sub.subscriptionPeriod === SUBSCRIPTION_PERIOD.MONTHLY);
    setSelectedSubscription(sub);
  }, [subscriptionList]);

  if (!selectedSubscription) {
    return null;
  }

  return (
    <>
      <ModalPreviewImg
        imgSrc={welcomeOffer?.photo}
        className={cn('absolute z-[1] w-full h-[calc(100%-400px)] sm:w-1/2 sm:h-full', className)}
      />
      <div className={cn('basis-1/2 grow')} />
      <div
        className={cn(
          'relative z-[3] py-3 pt-2 sm:pt-6 -mt-11 sm:mt-0',
          'flex flex-col justify-between shrink-0',
          'sm:justify-between sm:basis-1/2 grow',
        )}
      >
        <div className="flex flex-col">
          <Typography as="h6" weight="bold" variant="4xl" className={cn('px-3 text-primary-font')}>
            Limited Offer For You!
          </Typography>
          <div className="px-3 pb-4 pt-2">
            <div
              className="h-[118px] relative leading-none rounded-lg flex flex-col items-start gap-3 p-4 bg-no-repeat bg-cover"
              style={{ backgroundImage: `url('${welcomeOffer?.discount_bg}')` }}
            >
              <Typography as="p" weight="bold" className={cn('text-[44px] text-primary-font')}>
                -{welcomeOffer?.discount}%
              </Typography>
              <Typography
                as="p"
                weight="semibold"
                variant="base"
                className={cn(
                  'px-4 py-1 rounded-[10px] bg-brand-default inline flex gap-1 items-center text-brand-default-content',
                )}
              >
                Save a Total of {currencyConverter(currencyCode).format(savedPrice ?? 0)}
              </Typography>
              <Typography
                as="p"
                weight="semibold"
                className="absolute px-2 rounded-[10px] rotate-[15deg] py-1 text-sm right-14 top-6 premium-badge"
              >
                Only 2 left
              </Typography>
            </div>
          </div>
          <Typography
            as="div"
            weight="semibold"
            variant="base"
            className={cn('flex gap-2 items-center pb-2 uppercase px-3  text-primary-font')}
          >
            Premium benefits +
            <span className="flex items-center bg-grey-700 h-6 pr-2 rounded-md">
              <img src={creditImgPack[0]} className="size-10" alt="" />
              {welcomeOffer?.credits} FREE
            </span>
          </Typography>
          <SubscriptionBenefitList benefits={benefits} className={cn('px-3 gap-2')} />
        </div>
        <div className="pt-4 flex flex-col px-3 gap-2 shrink-0">
          <Typography
            as="span"
            weight="semibold"
            className={cn('px-3 text-lg flex items-center justify-center gap-1 text-primary-font')}
          >
            Just
            <Typography as="span" className="line-through">{originalPrice}</Typography>
            {currencyConverter(currencyCode).format(selectedSubscription?.price / 100)}/month
          </Typography>
          <Button className="w-full" size="lg" onClick={handleClickContinue} disabled={isDisabledContinue}>
            Subscribe Now {isDisabledContinue && <SpinnerIcon />}
          </Button>
          <span className="px-3 text-muted-foreground text-sm leading-snug text-center">
            You can cancel your subscription at any time <br />
            No hidden fees | Secured Payment.
          </span>
        </div>
      </div>
    </>
  );
};
