import { queryClient } from '@/core/config/react-query';
import { invalidateCharacterListQuery } from '@repo/modules/entity/chat-queries';
import { invalidateGetMeQuery } from '@repo/modules/entity/user-queries';
import { useGoogleSignUp } from '@repo/modules/feature/auth/api/goole-sign-up';

import { authModalAction } from '@/features/modals';

import { authStorageSetters } from '@/entities/auth';
import { invalidateGetConversations } from '@/entities/conversation';
import { useFirebase } from '@/entities/firebase';

import { Analytic } from '@/shared/services/analytic';

export interface GoogleSignUpFormControllerParams {
  onSuccess?: () => void;

  onError?: (error: unknown) => void;

  analyticData?: any;
}

export const useGoogleSignUpFormController = (params?: GoogleSignUpFormControllerParams) => {
  const { onSuccess, onError, analyticData } = params || {};
  const { onGoogleSignUp } = useGoogleSignUp();
  const firebase = useFirebase();

  const onSignUp = async (credential: any) => {
    Analytic.signUpCreateAccountClick(analyticData);
    const token_id = await firebase?.getCurrentUserToken(credential);

    try {
      const response = await onGoogleSignUp({ token_id });
      authStorageSetters.setToken(response.token);
      authStorageSetters.setAuth({ isAuthenticated: true });
      authModalAction.close();
      onSuccess?.();
      await invalidateGetMeQuery(queryClient);
      await invalidateGetConversations();
      await invalidateCharacterListQuery(queryClient);
    } catch (error) {
      onError?.(error);
      console.error('Error getting user token:', error);
    }
  };

  return {
    onSignUp,
  };
};
