import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { characterOptions, interests, useEditFormController } from './edit-form.controller';

import { useBrandFeatures } from '@/entities/brand';
import { CharacterAdapted } from '@/entities/character';
import { BadgeWithText, PersonalitySlider } from '@/entities/onboarding';

import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Nullable } from '@/shared/types';
import { Button, For, Icon, Input, Textarea } from '@/shared/ui';
import { Label } from '@/shared/ui/label';

export interface Props {
  character: Nullable<CharacterAdapted>;
  chatId?: string;
  closeEdit: () => void;

  setIsDirty: (isDirty: boolean) => void;
}

export const EditForm = (props: Props) => {
  const { character, chatId, setIsDirty, closeEdit } = props;

  const {
    control,
    observedValues,
    isSubmitButtonDisabled,
    isDirty,
    hasAccessToUpdate,
    handleClickInterest,
    onSubmit,
    onClickSubmitBtn,
    onSubmitPicture,
  } = useEditFormController(character, chatId, closeEdit);

  const features = useBrandFeatures();

  const icon = features?.change_face_edit_content?.icon ?? null;

  const picture =
    typeof observedValues.picture === 'string' ? observedValues.picture : URL.createObjectURL(observedValues.picture);

  useEffect(() => {
    Analytic.personaEditProfileView({ persona_id: character?.id, persona_name: character?.name });
  }, []);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  const changeFaceText = useMemo(() => {
    const cfgTxt = features?.change_face_edit_content?.text;
    if (typeof cfgTxt === 'string' && cfgTxt.length <= 0) return null;

    if (typeof cfgTxt === 'string' && cfgTxt.length > 0) return cfgTxt;

    return 'Change The Face';
  }, []);

  const handleOnClick = () => {
    Analytic.finishEditProfileClick({ persona_id: character?.id, persona_name: character?.name });
    onClickSubmitBtn?.();
  };

  if (!character) return null;

  return (
    <form onSubmit={onSubmit} className="flex flex-col w-full gap-5 pb-[40px] relative py-2 px-3" autoComplete="off">
      <div
        className="relative w-full basis-[380px] bg-cover bg-center rounded-lg"
        style={{ backgroundImage: `url(${picture})` }}
      >
        <div className="group absolute bottom-3 right-3 cursor-pointer">
          <Button
            className="bg-white/30 group-hover:bg-black/40 font-medium text-white group-hover:text-white z-[1] p-4"
            type="button"
            variant="ghost"
            size={changeFaceText ? 'md' : 'icon'}
            onClick={Analytic.addFaceClick}
          >
            <span className="inline-flex items-center gap-2">
              {icon && <Icon k={icon} className="text-[24px]" />}
              {changeFaceText}
            </span>
            <input
              type="file"
              className="opacity-0 absolute w-full h-full inset-x-0 top-0 z-[2]"
              onChange={onSubmitPicture}
            />
          </Button>
        </div>
      </div>

      <div className="field-container">
        <Label>Name</Label>
        <Controller
          control={control}
          name="name"
          render={({ field }) => <Input {...field} type="text" placeholder="Name" />}
        />
      </div>

      <div className="field-container">
        <Label>Interests</Label>
        <div className="flex items-center gap-2 flex-wrap">
          <For
            each={interests}
            render={(interest) => {
              const isActive = observedValues.interests.includes(interest.text);
              return (
                <div key={interest.id}>
                  <BadgeWithText
                    text={interest.text}
                    badgeSize="md"
                    isActive={isActive}
                    onClick={() => handleClickInterest(interest.text)}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>

      <div className="field-container">
        <Label>Character</Label>
        <div className="flex flex-col gap-5">
          <For
            each={characterOptions}
            render={(opt) => {
              return (
                <Controller
                  key={opt.id}
                  name={`character.${opt.meter}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <PersonalitySlider
                        value={[field.value]}
                        minLabel={opt.subText}
                        maxLabel={opt.text}
                        step={1}
                        onValueChange={(value) => field.onChange(value[0])}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="field-container">
        <Label>Age</Label>
        <Controller
          control={control}
          name="age"
          render={({ field }) => {
            return (
              <PersonalitySlider
                value={[field.value]}
                onValueChange={(value) => field.onChange(value[0])}
                min={18}
                max={99}
                step={1}
                showThumbValue
              />
            );
          }}
        />
      </div>

      <div className="field-container pt-4">
        <Label>Bio</Label>
        <Controller control={control} name="bio" render={({ field }) => <Textarea placeholder="Bio" {...field} />} />
      </div>

      <div
        className={cn('w-full pt-4', {
          'sticky bottom-[20px]': isDirty,
        })}
      >
        <Button
          disabled={isSubmitButtonDisabled}
          className="w-full relative"
          size="lg"
          type={hasAccessToUpdate ? 'submit' : 'button'}
          onClick={handleOnClick}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
