import { MouseEventHandler } from 'react';

import { Props as BuyCreditsModalProps, BuySubscriptionModal } from './BuySubscriptionModal';
import { DIALOG_KEYS } from '@/core/constants';
import { AUTH_FLOW_VARIANT } from '@repo/api/brand';
import { useIsSubscribed } from '@repo/modules/entity/user-queries';

import { authModalAction } from '@/features/modals';

import { authStorageGetters, useAuthFlowVariant } from '@/entities/auth';
import { useBrandFeatures } from '@/entities/brand';

import { Button, ButtonProps, Icon, dialog } from '@/shared/ui';

export interface Props extends ButtonProps {
  subscriptionVariant?: BuyCreditsModalProps['subscriptionVariant'];

  placementScreen?: string;

  label?: string;

  analyticData?: BuyCreditsModalProps['analyticData'];
}

export const BuySubscriptionModalTrigger = (props: Props) => {
  const { subscriptionVariant, label = 'Subscribe', analyticData, onClick, ...restProps } = props;

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const withSubscription = useIsSubscribed();

  const authFlowVariant = useAuthFlowVariant();

  const withAuth = AUTH_FLOW_VARIANT.AUTH_BEFORE_ACTION === authFlowVariant();

  const features = useBrandFeatures();

  const onOpenSubscriptionModal = () => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: <BuySubscriptionModal subscriptionVariant={subscriptionVariant} analyticData={analyticData} />,
    });
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (withAuth && !withSubscription && !isAuthenticated) {
      authModalAction.open(
        {
          onSuccess: (user) => {
            if (!user.is_subscribed) onOpenSubscriptionModal();
          },
        },
        {
          afterClose: onOpenSubscriptionModal,
        },
      );
    } else {
      onOpenSubscriptionModal();
    }
    onClick?.(event);
  };

  const icon = features?.subscription_btn_icon?.icon ?? null;

  return (
    <Button w="auto" onClick={handleClick} {...restProps}>
      <span className="flex items-center gap-1">
        {icon && <Icon k={icon} className="text-3xl" />} {label}
      </span>
    </Button>
  );
};
