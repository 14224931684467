import {
  ConstantposSubscription,
  type FintmSubscription,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_PLAN,
  type SubscriptionModel,
} from '../repositories';
import { SubscriptionType } from '@/core/constants';

export type AvailableSubscription = ConstantposSubscription | FintmSubscription | SubscriptionModel;

export type InternalSubscription = {
  id: string;
  value: number;
  price: number;
  subscriptionPlan: SUBSCRIPTION_PLAN;
  subscriptionPeriod: SUBSCRIPTION_PERIOD;
  lookup_key?: string;
  isBest: boolean;
  salePercentage: number;
};

const isSubscriptionWithProductId = (
  subscription: AvailableSubscription,
): subscription is FintmSubscription | ConstantposSubscription => {
  return 'product_id' in subscription;
};

export const internalSubscriptionAdapter = (subscription: AvailableSubscription) => {
  let result = {} as InternalSubscription;
  if (isSubscriptionWithProductId(subscription)) {
    result = {
      ...result,
      id: subscription.product_id,
      value: subscription.price,
      price: subscription.price,
      salePercentage: subscription.sale_percentage,
      subscriptionPlan: subscription.subscription_plan,
      subscriptionPeriod: subscription.subscription_period,
      isBest: subscription.is_best,
    };
  } else {
    const subscriptionPeriodMap: Record<SubscriptionType, SUBSCRIPTION_PERIOD> = {
      [SubscriptionType.PRO_ANNUAL]: SUBSCRIPTION_PERIOD.ANNUAL,
      [SubscriptionType.PRO_PLUS_ANNUAL]: SUBSCRIPTION_PERIOD.ANNUAL,
      [SubscriptionType.PRO_QUARTERLY]: SUBSCRIPTION_PERIOD.QUARTERLY,
      [SubscriptionType.PRO_PLUS_QUARTERLY]: SUBSCRIPTION_PERIOD.QUARTERLY,
      [SubscriptionType.PRO_WEEKLY]: SUBSCRIPTION_PERIOD.WEEKLY,
      [SubscriptionType.PRO_PLUS_WEEKLY]: SUBSCRIPTION_PERIOD.WEEKLY,
      [SubscriptionType.PRO_MONTHLY]: SUBSCRIPTION_PERIOD.MONTHLY,
      [SubscriptionType.PRO_PLUS_MONTHLY]: SUBSCRIPTION_PERIOD.MONTHLY,
    };

    const period = subscriptionPeriodMap[subscription.lookup_key] || SUBSCRIPTION_PERIOD.MONTHLY;

    const plan =
      subscription.lookup_key === SubscriptionType.PRO_ANNUAL ||
      subscription.lookup_key === SubscriptionType.PRO_MONTHLY ||
      subscription.lookup_key === SubscriptionType.PRO_QUARTERLY ||
      subscription.lookup_key === SubscriptionType.PRO_WEEKLY
        ? SUBSCRIPTION_PLAN.PRO
        : SUBSCRIPTION_PLAN.PRO_PLUS;

    result = {
      ...result,
      id: subscription.id,
      value: subscription.unit_amount,
      price: subscription.unit_amount,
      salePercentage: subscription.sale_percentage,
      subscriptionPlan: plan,
      subscriptionPeriod: period,
      isBest: period === SUBSCRIPTION_PERIOD.ANNUAL,
    };
  }

  return result;
};

export const internalSubscriptionListAdapter = (subscriptions: AvailableSubscription[]) => {
  return subscriptions.map(internalSubscriptionAdapter);
};
