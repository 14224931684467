import { Fragment, memo } from 'react';

import { NavItem as NavItemType, useRootLayoutContext } from '../../libs';
import './styles.scss';
import { chatStorage } from '@repo/modules/entities/chat/services';
import { UiIndicator, UiIndicatorProps } from '@repo/ui-kit/ui-indicator';
import { Link } from '@tanstack/react-router';

import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';

export interface Props {
  item: NavItemType;
  isHorizontal?: boolean;
}

export const NavItem = memo((props: Props) => {
  const { item, isHorizontal } = props;

  const { icon, label, hasUnreadBadge, ...restItem } = item;

  const { isOpenSidebar } = useRootLayoutContext();

  const withIconWrapper = !isOpenSidebar && hasUnreadBadge && !chatStorage.getVisitedChats();

  const IconWrapper = withIconWrapper ? UiIndicator : Fragment;
  const iconWrapperProps: UiIndicatorProps = withIconWrapper ? { as: 'span' } : {};

  return (
    <li>
      <IconWrapper {...iconWrapperProps}>
        <Link
          to={item.to ?? '/'}
          className={cn(
            'group',
            'flex items-center gap-2 p-3',
            'transition-colors rounded-2md font-semibold text-base',
            {
              'flex-col py-2 gap-1 !bg-transparent tab-bar-item': isHorizontal,
              'nav-item': !isHorizontal,
            },
          )}
          {...restItem}
        >
          <Typography
            as="span"
            variant="4xl"
            className={cn('inline-flex justify-center items-center', {
              '!text-[24px] tab-bar-item__icon': isHorizontal,
              'nav-item__icon': !isHorizontal,
            })}
          >
            {icon}
          </Typography>
          <span className="inline-flex items-center gap-2">
            <Typography
              as="span"
              weight="semibold"
              variant="base"
              className={cn('transition-all delay-0 inline-block overflow-clip whitespace-nowrap', {
                'invisible opacity-0': !isOpenSidebar && !isHorizontal,
                'text-xs tab-bar-item__label': isHorizontal,
                'nav-item__label': !isHorizontal,
              })}
            >
              {label}
            </Typography>
            {hasUnreadBadge && isOpenSidebar && !chatStorage.getVisitedChats() && <UiIndicator />}
          </span>
        </Link>
      </IconWrapper>
    </li>
  );
});
