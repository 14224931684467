import Age from '@/assets/icns/age.svg?react';
import Arrow from '@/assets/icns/arrow.svg?react';
import Blocks from '@/assets/icns/blocks.svg?react';
import BodyType from '@/assets/icns/body_type.svg?react';
import Brain from '@/assets/icns/brain.svg?react';
import BreastSize from '@/assets/icns/breast_size.svg?react';
import ButtSize from '@/assets/icns/butt_size.svg?react';
import Camera from '@/assets/icns/camera.svg?react';
import Chats from '@/assets/icns/chats.svg?react';
import Coronet from '@/assets/icns/coronet.svg?react';
import Create from '@/assets/icns/create.svg?react';
import Crown from '@/assets/icns/crown.svg?react';
import Discover from '@/assets/icns/discover.svg?react';
import Ethnicity from '@/assets/icns/ethnicity.svg?react';
import EyeCrossed from '@/assets/icns/eye_crossed.svg?react';
import EyeHide from '@/assets/icns/eye_hide.svg?react';
import EyeView from '@/assets/icns/eye_view.svg?react';
import EyesColor from '@/assets/icns/eyes_color.svg?react';
import Face from '@/assets/icns/face.svg?react';
import Fire from '@/assets/icns/fire.svg?react';
import Flash from '@/assets/icns/flash.svg?react';
import Gift from '@/assets/icns/gift.svg?react';
import HairColor from '@/assets/icns/hair_color.svg?react';
import HairStyle from '@/assets/icns/hair_style.svg?react';
import Lock2 from '@/assets/icns/lock-2.svg?react';
import Lock from '@/assets/icns/lock.svg?react';
import LogOut from '@/assets/icns/log_out.svg?react';
import Mask from '@/assets/icns/mask.svg?react';
import Pen from '@/assets/icns/pen.svg?react';
import Phone from '@/assets/icns/phone.svg?react';
import Photo from '@/assets/icns/photo.svg?react';
import Picture from '@/assets/icns/picture.svg?react';
import Play from '@/assets/icns/play.svg?react';
import Relationship from '@/assets/icns/relationship.svg?react';
import RollDice from '@/assets/icns/roll-dice.svg?react';
import Send from '@/assets/icns/send.svg?react';
import Settings from '@/assets/icns/settings.svg?react';
import Tattoo from '@/assets/icns/tattoo.svg?react';
import Timer from '@/assets/icns/timer.svg?react';

// const CreditCollection = {
//   coin: CreditCoin,
//   coupon: CreditCoupon,
//   crystal: CreditCrystal,
//   diamond: CreditDiamond,
//   coinage: CreditCoinage,
//   gold: CreditCoinage,
// } as const;

const CommonCollection = {
  lock: Lock,
  pen: Pen,
  send: Send,
  crown: Crown,
  'roll-dice': RollDice,
  face: Face,
  photo: Photo,
  chats: Chats,
  discover: Discover,
  settings: Settings,
  'eye-hide': EyeHide,
  'eye-view': EyeView,
  'eye-crossed': EyeCrossed,
  fire: Fire,
  mask: Mask,
  brain: Brain,
  blocks: Blocks,
  flash: Flash,
  'lock-2': Lock2,
  play: Play,
  timer: Timer,
  gift: Gift,
  'log-out': LogOut,
  phone: Phone,
  arrow: Arrow,
  age: Age,
  body_type: BodyType,
  ethnicity: Ethnicity,
  hair_color: HairColor,
  hair_style: HairStyle,
  breast_size: BreastSize,
  butt_size: ButtSize,
  tattoo: Tattoo,
  relationship: Relationship,
  eyes_color: EyesColor,
  create: Create,
  camera: Camera,
  picture: Picture,
  coronet: Coronet,
} as const;

const IconCollection = {
  // ...CreditCollection,
  ...CommonCollection,
} as const;

export type CommonIconKey = keyof typeof CommonCollection;

// export type CreditIconKey = keyof typeof CreditCollection;

export type IconKey = CommonIconKey;

export const getIcon = (k: IconKey) => {
  const icon = IconCollection[k];

  if (typeof icon !== 'function') {
    throw new Error(`"${k}" doesn't exist`);
  }

  return icon;
};
