import { useEffect } from 'react';

import { AUTH_FLOW_VARIANT } from '@repo/api/brand';
import { LocalStorageKey, localStorageService } from '@/core/services';
import type { routeTree } from '@/routeTree.gen';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { createFileRoute, Outlet, redirect, type RoutePaths, useSearch } from '@tanstack/react-router';

import { useConnectSocket } from '@/features/connect-socket';
import { authModalAction } from '@/features/modals';
import { RootLayout } from '@/features/root-layout';

import { authStorageGetters, useAuthFlowVariant } from '@/entities/auth';

import { useDisclosure } from '@/shared/hooks';
import { useBrandFeatures } from '@/entities/brand';

export const Route = createFileRoute('/_layout')({
  component: LayoutConversationComponent,
  beforeLoad: ({ context, location }) => {
    const system = context?.brandConfig?.features?.system;

    if (system && system.can_skip_onboarding) {
      localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
      return;
    }

    if (!context.isPassOnboarding()) {
      throw redirect({
        to: '/onboarding',
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const excludedPathForAuth: RoutePaths<typeof routeTree>[] = ['/404'];

function LayoutConversationComponent() {
  const { data: viewer } = useGetMeQuery();

  const { 0: isShownAuth, 1: actionShowAuth } = useDisclosure();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const authFlowVariant = useAuthFlowVariant();

  const withAuth = AUTH_FLOW_VARIANT.AUTH_BEFORE_ACTION === authFlowVariant();

  const withAuthRequired = AUTH_FLOW_VARIANT.AUTH_REQUIRED === authFlowVariant();

  const features = useBrandFeatures();

  const isRequiredAuth = (!features?.documents_warning_modal || localStorageService.getItem(LocalStorageKey.IsWarningModalShown, false)) && !isAuthenticated && withAuthRequired;

  const search = useSearch({
    strict: false,
  });

  useConnectSocket();

  const showAuth = () => {
    authModalAction.open();
    actionShowAuth.onOpen();
  };

  const showAuthRequired = () => {
    authModalAction.open(
      {
        isRequired: true,
        onSuccess: () => {
          authModalAction.close();
        },
      },
    );
  };

  useEffect(() => {
    const pathname = window.location.pathname as RoutePaths<typeof routeTree>;

    if (isRequiredAuth) {
      showAuthRequired();
    }

    if (!withAuth || excludedPathForAuth.includes(pathname)) return;

    if (search?.auth && !viewer?.is_registered) {
      showAuth();
      return;
    }

    if (viewer?.is_subscribed && !isShownAuth && !isAuthenticated) {
      showAuth();
    }
  }, [viewer, search]);

  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
}
