import { useDialog } from '../../_hooks';
import { AlertProps } from '../../types';
import { DialogContent } from '../dialog-content';
import { FloatingWrapper } from '../floating-wrapper';

export interface Props extends AlertProps {}

export const Alert = (props: Props) => {
  const { id, closeOnEsc } = props;

  const { context } = useDialog({
    isOpen: true,
    id,
    closeOnEsc,
  });

  return (
    <FloatingWrapper id={id} context={context}>
      <DialogContent className={id}></DialogContent>
    </FloatingWrapper>
  );
};
