import { HTMLAttributes, useEffect } from 'react';

import * as RadioGroup from '@radix-ui/react-radio-group';
import { radioGroupStyles, subscriptionsContent, titleStyles } from './buy-subscription-modal.config';
import { DIALOG_KEYS, SubscriptionTier } from '@/core/constants';
import { useFeature } from '@repo/common/services/features-book';

import { DiscountBanner } from '@/features/discount-banner';

import { useBrandFeatures } from '@/entities/brand';
import { ModalPreviewImg } from '@/entities/modals';
import { PriceRadioBtn, SubscriptionBenefitList, useSubscription } from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Alert, Button, dialog, For, SpinnerIcon, Typography } from '@/shared/ui';
import { CloseButton } from '@/shared/ui/close-button';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  subscriptionVariant?: SubscriptionTier;

  imgSrc?: string;

  analyticData?: any;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;

  isPaymentModal?: boolean;

  onSuccessOnboarding?: () => void;
}

export const BuySubscription = (props: Props) => {
  const {
    subscriptionVariant = SubscriptionTier.PRO,
    analyticData,
    imgSrc,
    onSuccessPayment,
    onFailedPayment,
    className,
    isPaymentModal,
    onSuccessOnboarding,
  } = props;

  const features = useBrandFeatures();

  const currentContent = subscriptionsContent(features)[subscriptionVariant];

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const subscriptionView = useFeature('subscription_paywall_view', 0);

  const { subscriptionList, selectedSubscription, setSelectedSubscription, handleClickContinue, isFetching } =
    useSubscription({
      subscriptionVariant,
      analyticData,
      onSuccessPayment,
      onFailedPayment,
      onSuccessOnboarding,
    });

  const isBlurred = Boolean(imgSrc);

  const onChangeSelectedSubscription = (subscriptionId: string) => {
    const sub = subscriptionList.find((sub) => sub.id === subscriptionId);
    setSelectedSubscription(sub);
  };

  const isDisabledContinue = !selectedSubscription || isFetching;

  const handleDeleteProgressAlert = () => {
    if (analyticData) {
      Analytic.paywallSkipClick(analyticData);
    }
    dialog.open({
      key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE,
      component: (
        <Alert
          type="error"
          onOkTitle="No"
          onCancelTitle="Delete"
          title="Are you sure you want to delete all the progress?"
          onCancelClick={() => {
            dialog.close({ key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE });
            onSuccessOnboarding?.();
          }}
          onOkClick={() => dialog.close({ key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE })}
          withActions
        />
      ),
      beforeOpen: () => Analytic.quizDeleteProgressView(),
    });
  };

  useEffect(() => {
    if (analyticData) {
      Analytic.paywallScreenView(analyticData);
    }
  }, []);

  useEffect(() => {
    if (!selectedSubscription) {
      const sub = subscriptionList.find((sub) => sub.isBest);
      setSelectedSubscription(sub);
    }
  }, [subscriptionList]);

  return (
    <>
      <ModalPreviewImg
        imgSrc={imgSrc ?? currentContent.picture}
        isBlurred={isBlurred}
        className={cn(
          'absolute z-[1] w-full h-[calc(100%-370px)] sm:w-1/2 sm:h-full',
          { 'relative grow h-full px-3 sm:px-0 pt-14 sm:pt-0': isBlurred },
          className,
        )}
      />
      {isPaymentModal && (
        <CloseButton className="w-4 h-6 p-0.5 z-10 opacity-70 mt-3 ml-3" onClick={handleDeleteProgressAlert} />
      )}
      <div className={cn('basis-2/3 sm:basis-1/2 grow', { hidden: isBlurred })} />
      <div
        className={cn(
          'relative z-[3] py-3 pt-2 sm:pt-6 -mt-11 sm:mt-0',
          'flex flex-col justify-between shrink-0 gap-4',
          'sm:justify-between sm:basis-1/2 sm:grow',
        )}
      >
        {isBlurred ? (
          <div className="hidden sm:block px-3">
            <Typography as="h6" weight="bold" variant="2xl" className="text-primary-font pb-2">
              {features?.modal_blurred_info.title}
            </Typography>
            <Typography as="span" weight="semibold" variant="base" className="text-secondary">
              {features?.modal_blurred_info.text}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Typography as="h6" weight="bold" variant="3xl" className={cn(titleStyles({ subscriptionView }))}>
              {currentContent.title[subscriptionView]}
            </Typography>
            {features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && <DiscountBanner />}
            <SubscriptionBenefitList
              subscriptionView={subscriptionView}
              benefits={currentContent.benefits}
              className={cn(
                'px-3',
                features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && 'grid grid-cols-2',
              )}
            />
          </div>
        )}
        <div className="flex flex-col px-3 gap-2 shrink-0">
          <RadioGroup.Root
            defaultValue={selectedSubscription?.id}
            value={selectedSubscription?.id}
            orientation="vertical"
            className={cn(radioGroupStyles({ subscriptionView }))}
            onValueChange={onChangeSelectedSubscription}
          >
            <For
              each={subscriptionList}
              render={(subscription) => {
                return (
                  <PriceRadioBtn
                    subscriptionView={subscriptionView}
                    discount={
                      features?.subscription_discount
                        ? features?.subscription_discount[subscription.subscriptionPeriod]
                        : subscription.salePercentage
                    }
                    currencyCode={currencyCode}
                    key={subscription.id}
                    value={subscription.id}
                    period={subscription.subscriptionPeriod}
                    price={subscription.price}
                    isBest={subscription.isBest}
                  />
                );
              }}
            />
          </RadioGroup.Root>
          <Button className="w-full" size="lg" onClick={handleClickContinue} disabled={isDisabledContinue}>
            {currentContent.button[subscriptionView]} {isDisabledContinue && <SpinnerIcon />}
          </Button>
          <span className="px-3 text-muted-foreground text-xs text-center">
            {features?.subscription_bottom_text
              ? currentContent.bottomText
              : 'You can cancel your subscription at any time'}
          </span>
        </div>
      </div>
    </>
  );
};
