import { useChatDrawerController } from './char-drawer.controller';
import { EditForm } from './edit-form';
import { Preview } from './preview';

import { DrawerHeader } from '@/widgets/chat-drawer/drawer-header';

import { ConversationDrawer } from '@/entities/conversation';

import { cn } from '@/shared/libs/utils';

interface Props {
  chatId?: string;
}

export const ChatDrawer = (props: Props) => {
  const { chatId } = props;

  const {
    character,
    isEdit,
    btnLabel,
    btnDisabled,
    btnChevron,
    onExitEdit,
    handleClose,
    handleClickEdit,
    handleClickReset,
    setIsDirtyEdit,
  } = useChatDrawerController({
    chatId,
  });

  const drawerHeader = (
    <DrawerHeader
      btnLabel={btnLabel}
      btnDisabled={btnDisabled}
      btnChevron={btnChevron}
      isEdit={isEdit}
      onClickReset={handleClickReset}
      onClose={handleClose}
      onClickEdit={handleClickEdit}
      className={cn('flex items-center justify-between gap-1', { 'p-3 md:border-b border-grey-600': !isEdit })}
    />
  );

  return (
    <ConversationDrawer.Drawer>
      {isEdit && <ConversationDrawer.Header>{drawerHeader}</ConversationDrawer.Header>}
      <ConversationDrawer.Content className="flex flex-col">
        {isEdit ? (
          <EditForm character={character} chatId={chatId} closeEdit={onExitEdit} setIsDirty={setIsDirtyEdit} />
        ) : (
          <div className="relative">
            {drawerHeader}
            <Preview onStartChatting={handleClose} onClickEdit={handleClickEdit} character={character} />
          </div>
        )}
      </ConversationDrawer.Content>
    </ConversationDrawer.Drawer>
  );
};
