import { ReactNode, useLayoutEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';

import { FeaturesBookNotifier, FeaturesBookProvider } from '@repo/common/services/features-book';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { UiSplashScreen } from '@repo/ui-kit/ui-splash-screen';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useTokenQuery } from '@/entities/auth';
import { useSuspenseGetBrandConfig } from '@/entities/brand';

import { getFeaturesBook, postExperimentViewed } from '@/shared/api/requests';
import { Analytic } from '@/shared/services/analytic';

export interface Props {
  children: ReactNode;
}

const readingNotifier: FeaturesBookNotifier = (_, value) => {
  if (!value?.experiment_key) return;
  dayjs.extend(utc);
  const timestamp = dayjs.utc().toISOString();

  return postExperimentViewed({
    experiment_key: value.experiment_key as string,
    variation_id: value?.experiment_variation_id as number,
    timestamp,
  });
};

export const AppInitialize = (props: Props) => {
  const { children } = props;

  const brandQuery = useSuspenseGetBrandConfig();

  const isLaunched = useRef(false);

  const createTokenQuery = useTokenQuery(brandQuery?.data?.payload?.constants?.api_token ?? '', {
    retry: false,
    enabled: brandQuery.isSuccess,
  });

  const meQuery = useGetMeQuery({
    enabled: createTokenQuery.isSuccess,
  });

  const initializeAnalytic = async ({
    googleTrackingId,
    amplitudeKey,
    isNewUser,
    sub,
  }: {
    amplitudeKey?: string;
    googleTrackingId?: string;
    sub?: string;
    isNewUser?: boolean;
  }) => {
    await Analytic.init({
      googleTrackingId,
      amplitudeKey,
    });
    if (sub) {
      Analytic.setUserId(sub);
    }
    if (isNewUser && !isLaunched.current) {
      isLaunched.current = true;
      Analytic.firstLaunch();
    }
  };

  useLayoutEffect(() => {
    if (!brandQuery.data || !meQuery.data || !createTokenQuery.data) return;
    initializeAnalytic({
      amplitudeKey: brandQuery.data.payload?.constants?.amplitude_key,
      googleTrackingId: brandQuery.data.payload?.constants?.google_tag_manager,
      sub: createTokenQuery.data.sub,
      isNewUser: createTokenQuery.data.isNewUser,
    });
  }, [brandQuery.data, meQuery.data, createTokenQuery.data]);

  useLayoutEffect(() => {
    if (!brandQuery.data?.payload.constants?.google_tag_manager) return;
    TagManager.initialize({
      gtmId: brandQuery.data?.payload.constants?.google_tag_manager,
    });
  }, [brandQuery.data]);

  if (brandQuery.isPending || meQuery.isPending || createTokenQuery.isPending) {
    return <UiSplashScreen spinnerKey="line-wobble" />;
  }

  return (
    <FeaturesBookProvider featuresGetter={getFeaturesBook} readingNotifier={readingNotifier}>
      {children}
    </FeaturesBookProvider>
  );
};
