import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Thumb } from '@radix-ui/react-scroll-area';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<typeof Thumb> {}

export const UiScrollAreaThumb = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, ...restProps } = props;
  return <Thumb ref={ref} className={cn('ui-scroll-area-thumb ui-bg-secondary/50', className)} {...restProps} />;
});
