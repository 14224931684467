import { HTMLAttributes } from 'react';

import { cva } from 'class-variance-authority';

import { SUBSCRIPTIONS_VIEW } from '@/entities/subscription';

import { cn } from '@/shared/libs/utils';
import { type CommonIconKey, For, Icon, Typography } from '@/shared/ui';

export type BenefitType = { key: string; label: string; icon: CommonIconKey };

export interface Props<T extends BenefitType> extends HTMLAttributes<HTMLUListElement> {
  benefits: T[];

  subscriptionView?: SUBSCRIPTIONS_VIEW;
}

export const benefitsStyles = cva('text-[--color-dialog]', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: '',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'pb-3',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const SubscriptionBenefitList = <T extends BenefitType>(props: Props<T>) => {
  const { benefits, className, subscriptionView = 0, ...restProps } = props;

  return (
    <ul className={cn('flex flex-col gap-1', className)} {...restProps}>
      <For
        each={benefits}
        render={(benefit) => {
          return (
            <li key={benefit.label} className="flex items-start gap-2">
              <Typography
                as="span"
                variant="3xl"
                className="inline-flex justify-center items-center text-brand-default"
              >
                <Icon k={benefit.icon} />
              </Typography>
              <Typography
                as="span"
                weight="semibold"
                variant="2sm"
                className={cn(benefitsStyles({ subscriptionView }))}
              >
                {benefit.label}
              </Typography>
            </li>
          );
        }}
      />
    </ul>
  );
};
