import { ElementType, HTMLAttributes, Ref } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cn } from '@repo/common/utils/component';
import { cva, VariantProps } from 'class-variance-authority';

type ElType = Extract<ElementType, 'p' | 'span' | 'div' | 'code' | 'pre' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;

const uiTypography = cva('', {
  variants: {
    variant: {
      '5xl': 'ui-text-5xl',
      '4xl': 'ui-text-4xl',
      '3xl': 'ui-text-3xl',
      '2xl': 'ui-text-2xl',
      xl: 'ui-text-xl',
      base: 'ui-text-base',
      '2sm': 'ui-text-2sm',
      sm: 'ui-text-sm',
      '2xs': 'ui-text-2xs',
      xs: 'ui-text-xs',
      inherit: 'text-inherit',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },

    select: {
      true: 'select-auto',
      false: 'select-none',
    },

    truncate: {
      true: 'truncate',
      false: '',
    },

    lineClamp: {
      0: '',
      1: 'line-clamp-1',
      2: 'line-clamp-2',
      3: 'line-clamp-3',
      4: 'line-clamp-4',
      5: 'line-clamp-5',
    },
  },
  defaultVariants: {
    weight: 'normal',
    select: true,
    truncate: false,
    lineClamp: 0,
  },
});

export interface Props<T extends ElType = 'p'> extends HTMLAttributes<T>, VariantProps<typeof uiTypography> {
  as?: T;

  asChild?: boolean;

  innerRef?: Ref<T>;
}

export const UiTypography = <T extends ElType>(props: Props<T>) => {
  const {
    as = 'p',
    className,
    variant,
    weight = 'normal',
    lineClamp = 0,
    truncate = false,
    select = true,
    asChild,
    children,
    ...restProps
  } = props;

  const Comp = asChild ? Slot : as;

  return (
    <Comp
      {...(restProps as any)}
      className={cn(
        uiTypography({
          variant,
          weight,
          lineClamp,
          truncate,
          select,
        }),
        className,
      )}
    >
      <Slottable>{children}</Slottable>
    </Comp>
  );
};
