import { ReactNode, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import loadable from '@loadable/component';
import { UiDialogContainer } from '@repo/ui-kit/ui-dialogs';
import { UiSplashScreen } from '@repo/ui-kit/ui-splash-screen';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { z } from 'zod';

import { ErrorCatcher } from '@/widgets/error-catcher';

import { useConnectUserToNotification } from '@/features/connect-user-to-notification';
import { RedirectProvider } from '@/features/redirect-provider';

import { type BrandPayloadModel } from '@/entities/brand';
import { useRedtrackClickId } from '@/entities/redtrack';

import { DialogContainer, ThemeProviderV2 } from '@/shared/ui';

interface RouteContext {
  queryClient: QueryClient;
  isPassOnboarding: () => boolean;
  brandConfig: BrandPayloadModel & {
    version: number;
  };
  featuresGetter: <T>(key: string, defaultValue?: T) => T;
}

const globalSearchSchema = z.object({
  auth: z.boolean().optional(),
  intent: z.string().optional(),
});

export const Route = createRootRouteWithContext<RouteContext>()({
  component: RootRoute,
  errorComponent: ErrorCatcher,
  validateSearch: globalSearchSchema,
});

function RootRoute() {
  const {
    brandConfig: { features, theme, constants },
  } = Route.useRouteContext();

  useRedtrackClickId();
  useConnectUserToNotification();

  const withGoogleOAuthProvider = (children: ReactNode) => {
    if (constants?.firebase_web_keys?.client_id) {
      const GoogleOAuthProvider = loadable(() => import('@react-oauth/google'), {
        resolveComponent: (module) => module.GoogleOAuthProvider,
      });
      return <GoogleOAuthProvider clientId={constants?.firebase_web_keys?.client_id}>{children}</GoogleOAuthProvider>;
    }
    return children;
  };

  return (
    <Suspense fallback={<UiSplashScreen spinnerKey="heart-beat-3d" />}>
      <>
        {withGoogleOAuthProvider(
          <RedirectProvider>
            <Helmet title={features?.app_name ?? 'AI'}>
              <link rel="icon" type="image/svg+xml" href={features?.logo?.favicon} />
              {features?.third_party?.red_track?.src && <script src={features?.third_party?.red_track?.src} />}
            </Helmet>

            <ThemeProviderV2 theme={theme as any}>
              <DialogContainer>
                <main className="h-full supports-dvh:h-dvh overflow-hidden bg-primary-gradient">
                  <Outlet />
                </main>
              </DialogContainer>
              <UiDialogContainer />
              {/* <TanStackRouterDevtools position="bottom-right"  /> */}
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            </ThemeProviderV2>
          </RedirectProvider>,
        )}
      </>
    </Suspense>
  );
}
