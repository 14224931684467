import { HTMLAttributes } from 'react';

import { For } from '../../common';
import { usePaginationController } from './pagination.controller';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const itemVariants = cva('flex items-center justify-center grow shrink-0 transition-all touch-manipulation', {
  variants: {
    variant: {
      dash: 'h-1 rounded-sm bg-white/30 data-[active=true]:bg-white',
      dot: 'h-[26px] w-[26px] basis-[26px] aspect-square rounded-full border-2 border-white/30 data-[active=true]:border-white bg-transparent',
    },
  },

  defaultVariants: {
    variant: 'dash',
  },
});

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof itemVariants> {}

export const Pagination = (props: Props) => {
  const { className, variant = 'dash', ...restProps } = props;

  const { scrollSnaps, selectedIndex, onClickItem } = usePaginationController();

  return (
    <div className={cn('flex items-center gap-2', className)} {...restProps}>
      <For
        each={scrollSnaps}
        render={(_, idx) => (
          <button
            key={idx}
            onClick={() => onClickItem(idx)}
            data-active={idx === selectedIndex}
            className={itemVariants({
              variant,
            })}
          />
        )}
      />
    </div>
  );
};
