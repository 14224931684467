import loadable from '@loadable/component';

import { useCreatePaymentIntentQuery } from '@/entities/subscription';

import { Modal, SpinnerIcon } from '@/shared/ui';
import { UiScrollArea } from '@repo/ui-kit/ui-scroll-area';

export interface Props {
  creditsType: string;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;
}

const CheckoutForm = loadable(() => import('../form'), {
  resolveComponent: (module) => module.CheckoutForm,
});

export const CreditsCheckoutModal = (props: Props) => {
  const { creditsType, onSuccessPayment, onFailedPayment } = props;

  const { data: paymentIntent, isLoading } = useCreatePaymentIntentQuery(
    { pack: creditsType },
    {
      refetchOnMount: 'always',
    },
  );

  return (
    <Modal.Root bodyClassName="bg-white" className="md:max-w-[512px]" fullWidth>
      <Modal.Header label="Checkout" className="bg-white text-black" closeBtnClass="bg-black/10" />
      <Modal.Content className="bg-white h-full min-h-[200px] px-6 pb-6 transition-[height]">
        <UiScrollArea.Root className="h-full grow">
          <UiScrollArea.Viewport className="scroll-smooth h-full">
            {!isLoading && paymentIntent && (
              <CheckoutForm
                onSuccessPayment={onSuccessPayment}
                onFailedPayment={onFailedPayment}
                clientSecret={paymentIntent.clientSecret}
              />
            )}
          </UiScrollArea.Viewport>
          <UiScrollArea.Scrollbar>
            <UiScrollArea.Thumb />
          </UiScrollArea.Scrollbar>
        </UiScrollArea.Root>
      </Modal.Content>
      {!paymentIntent && (
        <span
          className="absolute w-full h-full bg-black/10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center">
          <SpinnerIcon className="text-primary size-5" />
        </span>
      )}
    </Modal.Root>
  );
};
