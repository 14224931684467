import { memo, useCallback } from 'react';

import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { Outlet, createFileRoute } from '@tanstack/react-router';

import { CharacterConversationsList } from '@/widgets/character-conversations-list';
import { ChatDrawer } from '@/widgets/chat-drawer';

import { DiscountBanner } from '@/features/discount-banner';
import { BuySubscriptionModalTrigger } from '@/features/modals';

import { useBrandFeatures } from '@/entities/brand';
import {
  ConversationDrawer,
  ConversationSidebar,
  ConversationSidebarProvider,
  useGetChatIdParam,
} from '@/entities/conversation';

import { Analytic } from '@/shared/services/analytic';

export const Route = createFileRoute('/_layout/_layout-conversations')({
  component: memo(LayoutConversationComponent),
});

function LayoutConversationComponent() {
  const chatId = useGetChatIdParam();
  const { data: viewer } = useGetMeQuery();

  const features = useBrandFeatures();

  const rightSection = useCallback(() => {
    if (!viewer || viewer.is_subscribed) return null;
    return <BuySubscriptionModalTrigger onClick={() => Analytic.subscribeFromMainScreenClick()} />;
  }, [viewer]);

  return (
    <ConversationSidebarProvider chatId={chatId}>
      <div className="relative grid md:divide-x divide-grey-600 grid-cols-1 grid-rows-1 w-full md:grid-cols-[330px_1fr] h-full">
        <ConversationSidebar rightSection={rightSection}>
          {features?.discount_banner && !viewer?.is_subscribed && <DiscountBanner className="md:hidden" />}
          <CharacterConversationsList />
        </ConversationSidebar>

        <ConversationDrawer.Root key={chatId}>
          <div className="h-full grow">
            <Outlet />
          </div>
          <ChatDrawer chatId={chatId} />
        </ConversationDrawer.Root>
      </div>
    </ConversationSidebarProvider>
  );
}
