import { cx, CxOptions } from 'class-variance-authority';
import type { MutableRefObject } from 'react';
import type { Ref } from 'react';
import { ReactNode, SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactSlot } from '../types/helpers';

export function cn(...inputs: CxOptions) {
  return twMerge(cx(inputs));
}

export const mergeComponents = <T extends Function, O extends Record<string, any>>(component: T, rest: O): T & O => {
  return Object.assign(component, rest);
};

export const processReactNode = <T extends ReactNode | ((...args: any) => ReactNode)>(
  node: T,
  args?: T extends (...args: infer A) => ReactNode ? A : never,
): ReactNode => {
  const _args = Array.isArray(args) ? args : [args];
  return typeof node === 'function' ? node(..._args) : node;
};

export const nextRenderTick = (cb: () => void, timeout = 0) => setTimeout(cb, timeout);

export const isPrimitiveNode = (node: ReactSlot): node is string | number => {
  return typeof node === 'string' || typeof node === 'number';
};

export const preventDefault = (e: SyntheticEvent) => {
  e.preventDefault();
};

export function setRef<T>(ref: Ref<T> | undefined, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    (ref as MutableRefObject<T | null>).current = value;
  }
}

export const preventContextMenuActions = {
  onContextMenu: preventDefault,
  onMouseDown: preventDefault,
  onMouseUp: preventDefault,
  onTouchStart: preventDefault,
  onTouchEnd: preventDefault,
};
