import { memo } from 'react';
import { SpinnerProps } from '../type';
import './styles.css';

export const LineWobbleKey = 'line-wobble';

export interface Props extends SpinnerProps {}

export const LineWobble = memo((props: Props) => {
  const { ...restProps } = props;
  return <div data-variant={LineWobbleKey} {...restProps}></div>;
});
